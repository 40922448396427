import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Navbar, Nav, NavDropdown } from "react-bootstrap";

function App() {
  return (
    <div className="App">
      {/* Navbar */}
      <Navbar id="navbar" bg="light" variant="light" expand="sm">
        <Container>
          <Navbar.Brand>WB</Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-end">
            <Nav className="me-auto">
              <Nav.Link href="https://three.wardbenn.com" target="_blank" rel="noopener noreferrer">
                Three.js
              </Nav.Link>
              <Nav.Link href="https://react-mui.wardbenn.com" target="_blank" rel="noopener noreferrer">
                MUI
              </Nav.Link>
              <NavDropdown title="Wordpress" id="navbarScrollingDropdown">
                <NavDropdown.Item href="https://gtaprinting.ca" target="_blank" rel="noopener noreferrer">
                  GTA Printing
                </NavDropdown.Item>
                <NavDropdown.Item href="https://indoorgrass.ca" target="_blank" rel="noopener noreferrer">
                  Indoor Grass
                </NavDropdown.Item>
                <NavDropdown.Item href="https://wardswebdesigns.com" target="_blank" rel="noopener noreferrer">
                  Ward's Web Designs
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>

            {/* <Nav className="justify-content-end">
              <Nav.Item>
                <Nav.Link>Nav Item End</Nav.Link>
              </Nav.Item>
            </Nav> */}
          </Navbar.Collapse>
        </Container>
      </Navbar>

      {/* Main Content */}
      <Container>
        <Row>Welcome fred</Row>
      </Container>
    </div>
  );
}

export default App;
